import { CssBaseline, ThemeProvider } from '@mui/material'
import { Provider } from 'react-redux'
import './App.css'
import AreaMap from './Components/AreaMap'
import CurrentConditions from './Components/CurrentConditions'
import DetailedForecast from './Components/DetailedForecast'
import HourlyForecast from './Components/HourlyForecast'
import ButtonAppBar from './Components/Toolbar'
import WeeklyForecast from './Components/WeeklyForecast'
import store from './redux/store'
import theme from './Theme'

function App() {

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <Provider store={store}>
          <ButtonAppBar />
          <div style={{ margin: '10px 10%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', rowGap: '10px', columnGap: '10px' }}>
            <CurrentConditions />
            <HourlyForecast />
          </div>
          <WeeklyForecast />
          <div style={{ margin: '10px 10%', display: 'flex', flexDirection: 'row', columnGap: '10px', flexWrap: 'wrap' }}>
            <DetailedForecast />
            <AreaMap />
          </div>
        </Provider>
      </ThemeProvider>
    </div>
  )
}

export default App
