import { Accordion, AccordionDetails, AccordionSummary, Badge, Button, Card, CardActionArea, CardContent, CardHeader, CardMedia, Collapse, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Stack, styled, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { round, toCompass } from '../Utils/Math'
import { getWeatherImage } from '../Utils/Resources'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import moment from 'moment/moment'
import { useState } from 'react'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import parse from "html-react-parser"
import { getTextColorForHazard, getValueForHazard } from '../Utils/Color'




function CurrentConditions() {

    const forecastJson = useSelector(state => state.forecast.forecast)

    const [expanded, setExpanded] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [warningData, setWarningData] = useState([])

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props
        return <IconButton {...other} />
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(270deg)' : 'rotate(90deg)',
        marginLeft: 'auto',
        width: '40px',
        height: '40px',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }))


    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    const openHazardsDialog = () => {
        fetchHazardDetails().then(val => setDialogOpen(true))
    }

    const fetchHazardDetails = async () => {
        //https://forecast.weather.gov/showsigwx.php?warnzone=GAZ033&warncounty=GAC121&amp;firewxzone=GAZ033&amp;local_place1=2+Miles+ESE+Atlanta+GA&amp;product1=Hazardous+Weather+Outlook#.Y4aqlBTMK3A
        await fetch(`https://forecast.weather.gov/showsigwx.php?warnzone=${forecastJson.location.zone}&warncounty=${forecastJson.location.county}&amp;firewxzone=${forecastJson.location.firezone}&amp;local_place1=${forecastJson.location.areaDescription}`.replaceAll('amp;', ''))
            .then(response => response.text()).then(text => new DOMParser().parseFromString(text, "text/html")).then(html => html.getElementById('content'))
            .then(content => ({ titles: ([...content.getElementsByTagName('h3')].map(val => val.innerText)), bodies: ([...content.getElementsByTagName('pre')].map(val => val.innerText)) })).then(obj => obj.titles.map((val, index) => ({ title: val, body: obj.bodies[index] })))
            .then(warningD => warningD.sort((a, b) => getValueForHazard(b.title) - getValueForHazard(a.title))).then(warningD => setWarningData(warningD))
    }

    console.log(forecastJson)

    return (
        (!forecastJson) ? <></> :
            <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', minWidth: 'fit-content' }}>
                <Paper elevation={6} sx={{ padding: '10px' }}>
                    <Typography textAlign='left' fontSize={14}>Current Conditions At</Typography>
                    <Typography textAlign='left' fontWeight='bold' fontSize={18}>{forecastJson.currentobservation.name}</Typography>
                    <div style={{ display: 'flex' }}>
                        <Typography fontSize={12}>{parse(`<b>Lat:</b> ${forecastJson.location.latitude}°N`)}</Typography>
                        <Typography fontSize={12} paddingLeft='8px'>{parse(`<b>Lon:</b> ${forecastJson.location.longitude}°W `)}</Typography>
                        <Typography fontSize={12} paddingLeft='8px'>{parse(`<b>Elev:</b> ${forecastJson.location.elevation}ft.`)}</Typography>
                    </div>


                </Paper>
                <CardMedia sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0%', justifyContent: 'center', padding: '10px' }}>
                    <div style={{ display: 'flex', columnGap: '10px', alignItems: 'center' }}>
                        {forecastJson.data.hazard.length > 0 ? <Badge badgeContent={<Button sx={{ width: 20, height: 20, minWidth: 20, borderRadius: '50%' }} onClick={() => openHazardsDialog()}>{forecastJson.data.hazard.length}</Button>} color='error' sx={{
                            '& .MuiBadge-badge': { width: 20, height: 20, minWidth: 20, minHeight: 20, top: '5px', right: '5px', backgroundColor: getTextColorForHazard([...forecastJson.data.hazard].sort((a, b) => getValueForHazard(b) - getValueForHazard(a))[0]) },
                        }}>
                            {(forecastJson.currentobservation.Weatherimage !== 'NULL') ? <img width='148px' height='148px' style={{ borderRadius: '0px' }} src={getWeatherImage(forecastJson.currentobservation.Weatherimage)} /> : <BrokenImageIcon color='disabled' sx={{ borderRadius: '50%', width: '134px', height: '134px' }} />}

                        </Badge> : <img src={getWeatherImage(forecastJson.currentobservation.Weatherimage)} style={{ borderRadius: '5px', minWidth: '148px', minHeight: '148px' }}></img>}
                        <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
                            <DialogTitle>Hazards</DialogTitle>
                            <DialogContent>
                                {warningData.map(warning => (< Accordion >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                    >
                                        <Typography color={getTextColorForHazard(warning.title)}>{warning.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography component={'p'}>
                                            {parse(`${warning.body.replaceAll('\n', '<br>')}`, 'text/html')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                ))
                                }
                            </DialogContent>
                        </Dialog>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'div'} fontSize={14} textAlign={'left'}>{forecastJson.currentobservation.Weather}</Typography>
                            <Typography component={'div'} fontSize={42} fontWeight={'bold'} textAlign={'left'}>{forecastJson.currentobservation.Temp}°F</Typography>
                            <Typography component={'div'} fontSize={21} textAlign={'left'}>{round((forecastJson.currentobservation.Temp - 32) * 5 / 9, 0)}°C</Typography>
                        </div>
                        <Collapse in={expanded} timeout="auto" orientation='horizontal'>
                            <CardContent>
                                <Grid container wrap='nowrap' >
                                    <Grid item xs>
                                        <Typography noWrap fontSize={12} fontWeight={'bold'} display={'flex'} justifyContent='flex-end' flexGrow={1} paddingRight={'.5em'}>Humidity:</Typography>
                                        <Typography noWrap fontSize={12} fontWeight={'bold'} display={'flex'} justifyContent='flex-end' flexGrow={1} paddingRight={'.5em'}>Wind Speed:</Typography>
                                        <Typography noWrap fontSize={12} fontWeight={'bold'} display={'flex'} justifyContent='flex-end' flexGrow={1} paddingRight={'.5em'}>Barometer:</Typography>
                                        <Typography noWrap fontSize={12} fontWeight={'bold'} display={'flex'} justifyContent='flex-end' flexGrow={1} paddingRight={'.5em'}>Dewpoint:</Typography>
                                        <Typography noWrap fontSize={12} fontWeight={'bold'} display={'flex'} justifyContent='flex-end' flexGrow={1} paddingRight={'.5em'}>Visibility:</Typography>
                                        <Typography noWrap fontSize={12} fontWeight={'bold'} display={'flex'} justifyContent='flex-end' flexGrow={1} paddingRight={'.5em'}>Last update:</Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <Typography noWrap fontSize={12} display={'flex'} flexGrow={1}>{forecastJson.currentobservation.Relh}%</Typography>
                                        <Typography noWrap fontSize={12} display={'flex'} flexGrow={1}>{toCompass(forecastJson.currentobservation.Windd) + ' ' + forecastJson.currentobservation.Winds}</Typography>
                                        <Typography noWrap fontSize={12} display={'flex'} flexGrow={1}>{forecastJson.currentobservation.SLP + ' in (' + forecastJson.currentobservation.Altimeter + ' mb)'}</Typography>
                                        <Typography noWrap fontSize={12} display={'flex'} flexGrow={1}>{forecastJson.currentobservation.Dewp}°F ({round((forecastJson.currentobservation.Dewp - 32) * 5 / 9, 0)}°C)</Typography>
                                        <Typography noWrap fontSize={12} display={'flex'} flexGrow={1}>{forecastJson.currentobservation.Visibility} mi</Typography>
                                        <Typography noWrap fontSize={12} display={'flex'} flexGrow={1}>{moment(forecastJson.currentobservation.Date, 'DD MMM HH:mm a ').format('DD MMM hh:mm a ').toString() + forecastJson.currentobservation.timezone}</Typography>

                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Collapse>
                        <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"

                        >
                            <ExpandMoreIcon />
                        </ExpandMore>
                    </div>
                </CardMedia>
            </Card >
    )
}

export default CurrentConditions