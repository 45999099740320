import { configureStore } from '@reduxjs/toolkit'
import forecastReducer from './reducers/forecastSlice'
import hourlyReducer from './reducers/hourlySlice'
import recentsReducer from './reducers/recentsSlice'

export default configureStore({
    reducer: {
        forecast: forecastReducer,
        hourly: hourlyReducer,
        recents: recentsReducer,
    },
})