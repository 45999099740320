import { createSlice } from '@reduxjs/toolkit'

export const recentsSlice = createSlice({
    name: 'recents',
    initialState: {
        recentsJson: [],
    },
    reducers: {
        setRecents: (state, action) => {
            state.recentsJson = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setRecents } = recentsSlice.actions

export default recentsSlice.reducer