import { alpha, Badge, Button, Divider, IconButton, LinearProgress, linearProgressClasses, Stack, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setForecast } from '../redux/reducers/forecastSlice'
import { getWeatherImage } from '../Utils/Resources'
import WarningIcon from '@mui/icons-material/Warning'
import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import { getTextColorForHazard, getValueForHazard } from '../Utils/Color'
import { removeFromRecents, updateRecents, updateRecentsOrder } from '../Utils/LocalStorage'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import FavoriteIcon from '@mui/icons-material/Favorite'
import CloseIcon from '@mui/icons-material/Close'

function RecentLocations() {

    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: alpha(theme.palette.primary.main, .3),
        },
    }))

    const StyledButton = styled(Button)({
        '&:hover': {
            backgroundColor: alpha('#e6e6e6', .3),
        },
    })

    const [locationWeatherArray, setLocationWeatherArray] = useState([])
    const [loading, setLoading] = useState(-1)
    const [closeIndex, setCloseIndex] = useState(-1)

    const forecastJson = useSelector(state => state.forecast.forecast)
    const recents = useSelector(state => state.recents.recentsJson)

    const dispatch = useDispatch()

    useEffect(() => {
        updateWeather()
    }, [recents])

    function needUpdate(val) {
        return !val.weather || val.weather.length < 1 || !val.lastUpdated || Date.now() - val.lastUpdated > 60000
    }

    function updateWeather() {
        if (recents.length > 0) {
            const recentsCpy = [...recents]
            let templocationWeatherArray = recentsCpy.sort((a, b) => b.favorite ? 1 : -1).slice(0, 3).map(value => ({ address: value.address, x: value.x, y: value.y, weather: locationWeatherArray.find(val => val.address === value.address)?.weather, lastUpdated: locationWeatherArray.find(val => val.address === value.address)?.lastUpdated, favorite: value.favorite }))
            console.log(templocationWeatherArray)
            const updateLength = templocationWeatherArray.filter(val => needUpdate(val)).length
            console.log(updateLength)
            let count = 0
            templocationWeatherArray.forEach((_value, index) => {
                if (needUpdate(templocationWeatherArray[index])) {
                    fetch(`https://forecast.weather.gov/MapClick.php?&lat=${templocationWeatherArray[index].y}&lon=${templocationWeatherArray[index].x}&FcstType=json`)
                        .then(response => response.json()).then(json => {
                            templocationWeatherArray[index] = { ...templocationWeatherArray.slice(index, index + 1)[0], weather: json, lastUpdated: Date.now() }
                            if (templocationWeatherArray[index].favorite) {
                                dispatch(setForecast(json))
                            }
                            templocationWeatherArray =
                                [...templocationWeatherArray.slice(0, index),
                                { ...templocationWeatherArray.slice(index, index + 1)[0] },
                                ...templocationWeatherArray.slice(index + 1)
                                ]
                        }).then(() => {
                            ++count
                            console.log("Count " + count)
                            if (count >= updateLength) {
                                setLocationWeatherArray(templocationWeatherArray)
                            }
                        })
                } else {
                    templocationWeatherArray =
                        [...templocationWeatherArray.slice(0, index),
                        { ...templocationWeatherArray.slice(index, index + 1)[0] },
                        ...templocationWeatherArray.slice(index + 1)
                        ]

                }
                const favorite = templocationWeatherArray.find(val => val.favorite)
                if (favorite) {
                    templocationWeatherArray.splice(templocationWeatherArray.indexOf(favorite), 1)
                    templocationWeatherArray.unshift(favorite)
                }
                setLocationWeatherArray(templocationWeatherArray)
            })
        } else {
            setLocationWeatherArray([])
        }
    }

    function handleUpdateFavorite(index) {
        console.log(index)
        let templocationWeatherArray = locationWeatherArray.map((val, index1) => {
            if (index === index1) {
                return { ...val, favorite: !val.favorite }
            } else {
                return { ...val, favorite: false }
            }
        })
        let favoriteLocation = templocationWeatherArray[index]
        if (favoriteLocation.favorite) {
            templocationWeatherArray.splice(templocationWeatherArray.indexOf(favoriteLocation), 1)
            templocationWeatherArray.unshift(favoriteLocation)
        }
        updateRecents(templocationWeatherArray.map(value => ({ address: value.address, x: value.x, y: value.y, favorite: value.favorite })), dispatch)
        setLocationWeatherArray(templocationWeatherArray)
    }

    function handleDeleteLocation(index) {
        const item = locationWeatherArray[index]
        console.log(item)
        console.log(recents)
        removeFromRecents(recents.findIndex(val => val.address === item.address && val.x === item.x && val.y === item.y), dispatch)
    }

    return (
        locationWeatherArray.length < 1 ? <></> :
            <>
                <Stack direction={'row'} >
                    {locationWeatherArray.slice(0, 3).map((value, index) => {
                        const weather = value.weather
                        if (weather && weather.currentobservation) {
                            return (
                                <div key={value.address} style={{ display: 'grid', gridTemplate: '1fr / 1fr', position: 'relative' }} >
                                    {loading === index && (
                                        <BorderLinearProgress sx={{ gridColumn: `${index * 2 + 1}/${index * 2 + 1}`, gridRow: '1 / 1', height: 'inherit', margin: '5px' }}></BorderLinearProgress>)}
                                    <StyledButton sx={{ gridColumn: `${index * 2 + 1}/${index * 2 + 1}`, gridRow: '1 / 1', margin: '5px', padding: '6px 45px 6px 8px', backgroundColor: (loading && weather.location?.areaDescription === forecastJson?.location?.areaDescription) ? alpha('#e6e6e6', .2) : 'inherit' }} onClick={async () => {
                                        updateRecentsOrder(recents, index, dispatch)
                                        if (Date.now() - locationWeatherArray[index].lastUpdated < 60000) {
                                            return dispatch(setForecast(weather))
                                        } else {
                                            setLoading(index)
                                            fetch(`https://forecast.weather.gov/MapClick.php?&lat=${value.y}&lon=${value.x}&FcstType=json`)
                                                .then(response => response.json()).then(forecastJson => dispatch(setForecast(forecastJson))).finally(() => setLoading(-1))
                                        }
                                    }
                                    } onMouseEnter={_event => setCloseIndex(index)} onMouseLeave={_event => setCloseIndex(-1)}>
                                        {(weather.currentobservation.Weatherimage !== 'NULL' && weather.currentobservation.Weatherimage !== null) ? <img width={'40px'} height={'40px'} style={{ borderRadius: '50%' }} src={getWeatherImage(weather.currentobservation.Weatherimage)} /> : <BrokenImageIcon color='disabled' sx={{ borderRadius: '50%', width: '40px', height: '40px' }} />}
                                        <Typography fontSize={'.875rem'} paddingLeft='5px'>{weather?.currentobservation.Temp}°</Typography>
                                        <Typography noWrap fontSize={'.875rem'} paddingLeft='5px'>{value.address.replace(/\d{5},/, '').replace(/, USA/, '').replace(/ \([a-zA-Z]+ County\)/, '')}</Typography>
                                        {weather.data.hazard.length > 0 ? <Badge badgeContent={weather.data.hazard.length} color='neutral' sx={{
                                            paddingLeft: '5px', '& .MuiBadge-badge': { width: 15, height: 15, minWidth: 15, minHeight: 15, top: '2px', backgroundColor: getTextColorForHazard([...weather.data.hazard].sort((a, b) => getValueForHazard(b) - getValueForHazard(a))[0]) }
                                        }}><WarningIcon sx={{ color: 'red' }} /></Badge> : <></>}
                                    </StyledButton>
                                    <IconButton sx={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }} onMouseEnter={_event => setCloseIndex(index)} onMouseLeave={_event => setCloseIndex(-1)} onClick={_event => handleUpdateFavorite(index)}>
                                        {locationWeatherArray[index].favorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                    </IconButton>
                                    {closeIndex === index ? <IconButton sx={{ position: 'absolute', top: '5px', right: '5px', height: 4, width: 4 }} onMouseEnter={_event => setCloseIndex(index)} onMouseLeave={_event => setCloseIndex(-1)} onClick={_event => handleDeleteLocation(index)}>
                                        <CloseIcon sx={{ height: 20, width: 20 }} />
                                    </IconButton> : <></>}
                                    {(index > 1 || index >= locationWeatherArray.length - 1) ? <></> : <Divider sx={{ gridColumn: `${index * 2 + 2}/${index * 2 + 2}`, gridRow: '1 / 1' }} orientation='vertical' variant='middle' flexItem></Divider>}
                                </div>
                            )
                        } else {
                            console.log("Null Weather")
                            return (
                                <div key={value.address} style={{ display: 'grid', gridTemplate: '1fr / 1fr' }} >
                                    <BorderLinearProgress sx={{ gridColumn: `${index * 2 + 1}/${index * 2 + 1}`, gridRow: '1 / 1', width: `${value.address.replace(/\d{5},/, '').replace(/, USA/, '').replace(/ \([a-zA-Z]+ County\)/, '').length * 15.5}px`, height: '50px', margin: '5px' }}></BorderLinearProgress>
                                    {(index > 1 || index >= locationWeatherArray.length - 1) ? <></> : <Divider sx={{ gridColumn: `${index * 2 + 2}/${index * 2 + 2}`, gridRow: '1 / 1' }} orientation='vertical' variant='middle' flexItem></Divider>}
                                </div>
                            )
                        }

                    })}
                </Stack>
            </>
    )
}

export default RecentLocations
