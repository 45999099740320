import { Card, Paper, Stack, Typography } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import React from 'react'
import { useSelector } from 'react-redux'

function DetailedForecast() {

    const forecastJson = useSelector(state => state.forecast.forecast)

    return (
        forecastJson ?

            <Card variant='outlined' sx={{ margin: '10px 0% 0px 0%', display: 'flex', flexDirection: 'column', flexBasis: '500px', flexGrow: 1 }}>
                <Paper sx={{ padding: '10px' }} elevation={6}>
                    <Typography fontSize={18} textAlign='left'>Detailed Forecast</Typography>
                </Paper>
                <Stack direction='column' spacing={1} sx={{ gridTemplateColumns: 'auto 1fr' }} display='grid'>
                    {forecastJson.time.startPeriodName.map((time, index) => <Typography fontWeight='bold' textAlign='right' style={{ gridRow: index + 1, gridColumn: 1, marginTop: '10px', paddingLeft: '10px', paddingRight: '10px', backgroundColor: (index % 2) ? blueGrey[900] : 'inherit' }} key={`time${index}}`}>{time + ': '}</Typography>)}
                    {forecastJson.data.text.map((text, index) => <Typography textAlign='left' style={{ gridRow: index + 1, gridColumn: 2, paddingRight: '10px', marginTop: '10px', backgroundColor: (index % 2) ? blueGrey[900] : 'inherit' }} key={`text${index}}`}> {text}</Typography>)}
                </Stack>
            </Card >
            : <></>
    )
}

export default DetailedForecast