import { Button, Card, Icon, IconButton, Paper, Stack, styled, Typography } from '@mui/material'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { blue, red } from '@mui/material/colors'
import { setHourlyTimeframe } from '../redux/reducers/hourlySlice'

function WeeklyForecast() {

    const dispatch = useDispatch()

    const forecastJson = useSelector(state => state.forecast.forecast)
    const hourlyJsonData = useSelector(state => state.hourly.hourlyJsonData)

    const StyledStack = styled(Stack)({
        '&:hover': {
            overflowX: 'overlay',
        },
    })

    return (
        forecastJson ?
            <Card variant='outlined' sx={{ margin: '10px 10%', display: 'flex', flexDirection: 'column' }}>
                <Paper sx={{ padding: '10px' }} elevation={6}>
                    <Typography fontSize={14} textAlign='left'>Extended Forecast for</Typography>
                    <Typography fontSize={18} textAlign='left'>{forecastJson.location.areaDescription}</Typography>
                </Paper>
                <StyledStack direction='column' rowGap={2} padding='10px' sx={{ overflowX: 'hidden' }}>
                    <Stack direction='row' columnGap={5}>
                        {forecastJson.time.startPeriodName.slice(0, 9).map((val, index) => <Typography fontSize='14pt' textAlign='center' display='flex' flexDirection='column' flex={1} justifyContent='center' minWidth='102px' key={`timeFrame${index}`}>
                            {val}
                        </Typography>)}
                    </Stack>
                    <Stack direction='row' columnGap={5}>
                        {forecastJson.data.iconLink.slice(0, 9).map((val, index) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '1', justifyContent: 'center'}} key={`forecastIcons${index}`}>
                                    <Button onClick={() => dispatch(setHourlyTimeframe(forecastJson.time.startPeriodName[0].replaceAll(' ', '') !== hourlyJsonData.PeriodNameList[0].replaceAll(' ', '') ? index + 1 : index))} style={{ display: 'flex', flexDirection: 'row', flex: '1', justifyContent: 'center', borderRadius: '0px', maxWidth: '130px', maxHeight: '130px' }}>
                                        <img src={val} style={{ display: 'flex', flex: '1', maxHeight: '130px', maxWidth: '130px', borderRadius: '5px' }}></img>
                                    </Button>
                                </div>
                            )
                        })
                        }
                    </Stack>
                    <Stack direction='row' columnGap={5}>
                        {forecastJson.data.weather.slice(0, 9).map((val, index) => <Typography display='flex' flexDirection='column' fontSize='16px' flex={1} minWidth='102px' key={`High${index}`}>{val}</Typography>)}
                    </Stack>
                    <Stack direction='row' columnGap={5} paddingBottom={1}>
                        {forecastJson.time.tempLabel.slice(0, 9).map((val, index) => <Typography color={val === 'Low' ? red[200] : blue[200]} display='flex' flexDirection='column' flex={1} minWidth='102px' key={`Temperature${index}`}>{val + ': ' + forecastJson.data.temperature[index] + '°F'}</Typography>)}
                    </Stack>
                </StyledStack>
            </Card>
            : <></>
    )
}

export default WeeklyForecast