import { createTheme } from "@mui/material";


const theme = createTheme({
    palette: {
        mode: 'dark',
        background: {},
        primary: {
            main: '#fff',
            dark: '#1b1b1b',
            light: '#6d6d6d',
            contrastText: '#424242',
        },
        secondary: {
            contrastText: '#fff',
            dark: '#38006b',
            light: '#9c4dcc',
            main: '#6a1b9a',
        },
        neutral: {
            main: '#64748B',
            contrastText: '#fff',
        },
        link: '#9c4dcc',
    },
})


export default theme;