import { createSlice } from '@reduxjs/toolkit'

export const forecastSlice = createSlice({
    name: 'forecast',
    initialState: {
        forecast: null,
    },
    reducers: {
        /**
         * 
         * @param {any} state The state to update.
         * @param {any} action The action containing the payload.
         */
        setForecast: (state, action) => {
            state.forecast = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setForecast } = forecastSlice.actions

export default forecastSlice.reducer