import { blueGrey, orange, red, yellow } from "@mui/material/colors"

export const getTextColorForHazard = (s) => {
    if (s.includes('Warning')) {
        return red[800]
    } else if (s.includes('Watch')) {
        return orange[800]
    } else if (s.includes('Advisory')) {
        return yellow[800]
    } else if (s.includes('Outlook')) {
        return blueGrey[700]
    }
}

export const getValueForHazard = (s) => {
    if (s.includes('Warning')) {
        return 3
    } else if (s.includes('Watch')) {
        return 2
    } else if (s.includes('Advisory')) {
        return 1
    }
    return 0
}