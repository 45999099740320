import { setRecents } from '../redux/reducers/recentsSlice'

export function recentsUpdated(dispatch) {
    const recentLocalStorage = window.localStorage.getItem('recents')
    if (recentLocalStorage) {
        const json = JSON.parse(recentLocalStorage)
        dispatch(setRecents(json))
    }
}

export function updateRecentsOrder(json, index, dispatch) {
    const orderedJson = [...json]
    orderedJson.unshift(orderedJson.splice(index, 1)[0])
    window.localStorage.setItem('recents', JSON.stringify(orderedJson))
    dispatch(setRecents(orderedJson))
}

export function updateRecents(json, dispatch) {
    window.localStorage.setItem('recents', JSON.stringify(json))
    dispatch(setRecents(json))
}

export function addLocalStorage(location, geom, dispatch) {
    const recentLocalStorage = window.localStorage.getItem('recents')
    if (recentLocalStorage) {
        let json = JSON.parse(recentLocalStorage)
        const temp = JSON.parse(`{"address":"${location.text}","x":${geom.x},"y":${geom.y},"favorite":0}`)

        json = json.filter(value => value.address !== location.text)
        json.unshift(temp)
        updateRecents(json, dispatch)
    } else {
        const json = `[{"address":"${location.text}","x":${geom.x},"y":${geom.y},"favorite":1}]`
        updateRecents(JSON.parse(json), dispatch)
    }
    return geom
}

export function removeFromRecents(index, dispatch) {
    console.log(index)
    const recentLocalStorage = window.localStorage.getItem('recents')
    const json = JSON.parse(recentLocalStorage)
    console.log(json)
    const tempArray = [...json]
    const deleted = tempArray.splice(index, 1)
    console.log("Deleted: " + JSON.stringify(deleted))
    window.localStorage.setItem('recents', JSON.stringify(tempArray))
    console.log(tempArray)
    dispatch(setRecents(tempArray))
}