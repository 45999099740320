import React, { useEffect, useRef, useState } from 'react'
import { Fill, Stroke, Style } from 'ol/style'
import { fromLonLat } from 'ol/proj'
import FullScreenControl from '../Controls/FullScreenControl'
import { Button, Card, Dialog, Paper, Stack, Tooltip, Typography } from '@mui/material'
import Map from '../Map/Map'
import Controls from '../Controls/Controls'
import { useSelector } from 'react-redux'
import parse from "html-react-parser"
import { blueGrey } from '@mui/material/colors'
import { Feature } from 'ol'
import { Point } from 'ol/geom'
import moment from 'moment/moment'

let styles = {
    'MultiPolygon': new Style({
        stroke: new Stroke({
            color: 'blue',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),
    }),
}

function AreaMap() {

    const forecastJson = useSelector(state => state.forecast.forecast)

    const [center, setCenter] = useState()
    const [zoom, setZoom] = useState(9)
    const [discussionOpen, setDiscussionOpen] = useState(false)
    const [forecastDiscussionText, setForecastDiscussionText] = useState('Loading...')
    const [glossary, setGlossary] = useState()

    const hrefs = useRef([])

    useEffect(() => {
        if (!forecastJson || !discussionOpen) return
        const pattern = /--[^<>]+--/g

        if (!glossary) {
            fetch('https://api.weather.gov/glossary').then(val => val.json()).then(val => setGlossary(val.glossary))
        }
        //https://api.weather.gov/glossary

        let tempForecastDiscussionText

        fetch(`https://forecast.weather.gov/MapClick.php?&lat=${forecastJson.location.latitude}&lon=${forecastJson.location.longitude}`)
            .then(response => response.text())
            .then(text => new DOMParser().parseFromString(text, "text/html"))
            .then(val => val.querySelector("#linkBlockContainer > div:nth-child(1) > ul > li:nth-child(1) > a").href)
            .then(val => fetch(val))
            .then(response => response.text())
            .then(text => new DOMParser().parseFromString(text, "text/html"))
            .then(val => val.querySelector("pre.glossaryProduct"))
            .then(val => { tempForecastDiscussionText = (val.innerText); return val })
            .then(val => [...val.querySelectorAll("a[href][onClick]")]
                .map(href => href.text).sort((a, b) => b.length - a.length))
            .then(hrefVals => hrefs.current = hrefVals.filter((value, index, self) => self.indexOf(value) === index))
            .then(() => setForecastDiscussionText(tempForecastDiscussionText.replaceAll('\n', '<br/>').replaceAll(pattern, '')))
    }, [discussionOpen])

    function getDiscussionTextWithTooltips() {
        if (!hrefs) {
            return
        }

        return (
            <div>
                {forecastDiscussionText.split(new RegExp(`(${hrefs.current.map(val => `\\b${val}\\b`).join('|')})`)).map((val, index) => {
                    if (hrefs.current.find(href => href === val)) {
                        const term = glossary?.find(term => term.term?.toLowerCase() === val?.toLowerCase())
                        return <Tooltip title={<Typography whiteSpace='pre-line' component='pre'>{term ? parse(term.definition.replaceAll('<br>', '', 'text/html')) : 'Unknown'}</Typography>} key={`tooltip${index}`}>
                            <Typography component={'a'} sx={{ textDecoration: 'underline', color: blueGrey[200] }}>{parse(`${val}`, 'text/html')}</Typography>
                        </Tooltip>
                    } else {
                        return <Typography component={'a'} key={`typography${index}`}>{val === ' ' ? val : parse(`${val}`, 'text/html')}</Typography>
                    }
                })}
            </div>
        )
    }

    return (
        forecastJson ?
            <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', minWidth: 'fit-content', marginTop: '10px' }}>
                <Paper elevation={6} sx={{ padding: '10px' }}>
                    <Typography fontSize={16}>Click Map For Forecast</Typography>
                </Paper>
                <Map center={fromLonLat([forecastJson.location.longitude, forecastJson.location.latitude])} zoom={zoom} features={[
                    new Feature(
                        { geometry: new Point(fromLonLat([forecastJson.location.longitude, forecastJson.location.latitude])) }
                    )
                ]}>
                    <Controls>
                        <FullScreenControl />
                    </Controls>
                </Map>
                <Stack direction='row'>
                    <Stack direction='column'>
                        <Typography textAlign='left'>Point Forecast: {forecastJson.location.areaDescription + " " + forecastJson.location.latitude + "°N " + forecastJson.location.longitude + "°W (Elev. " + forecastJson.location.elevation + " ft)"}</Typography>
                        <Typography textAlign='left'>Last Update: {forecastJson.creationDateLocal}</Typography>
                        <Typography textAlign='left'>Forecast Valid: {moment(forecastJson.time.startValidTime[0], 'YYYY-MM-DDTHH:mm:ss-00:00 ').format('D MMM h:mm a').toString() + " - " + moment(forecastJson.time.startValidTime[forecastJson.time.startValidTime.length - 1], 'YYYY-MM-DDTHH:mm:ss-00:00 ').format('D MMM h:mm a ').toString() + forecastJson.currentobservation.timezone}</Typography>
                    </Stack>
                    <Stack direction='column'>
                    </Stack>
                </Stack>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button variant='text' onClick={() => setDiscussionOpen(true)}><Typography>Forecast Discussion</Typography></Button>
                </div>
                <Dialog open={discussionOpen} fullWidth sx={{ '.MuiDialog-paper': { padding: '20px', minWidth: 'fit-content', display: 'inline-block', overflowX: 'hidden' } }} onClose={() => { setDiscussionOpen(false) }}>
                    {forecastDiscussionText ? getDiscussionTextWithTooltips() : <></>}
                </Dialog>
            </Card>
            : <></>
    )
}

export default AreaMap