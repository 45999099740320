import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import WeatherInput from './WeatherInput'
import RecentLocations from './RecentLocations'
import { useDispatch, useSelector } from 'react-redux'
import { recentsUpdated } from '../Utils/LocalStorage'

export default function ButtonAppBar() {


    const recents = useSelector(state => state.recents.recentsJson)

    const dispatch = useDispatch()

    React.useEffect(() => {
        recentsUpdated(dispatch)
    }, [])

    return (
        !recents ? <></> :
            <Box sx={{ flexGrow: 1, backgroundColor: '#121212' }}>
                <AppBar position="static" color={'primary'} >
                    <Toolbar disableGutters sx={{ width: '80%', margin: '0 10%', display: 'flex' }}>
                        <WeatherInput recentsUpdated={() => recentsUpdated()} />
                        <RecentLocations recents={recents} />
                    </Toolbar>
                </AppBar>
            </Box>
    )
}