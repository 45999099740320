import React, { useRef, useState, useEffect } from "react"
import "./Map.css"
import MapContext from "./MapContext"
import * as ol from "ol"
import { toLonLat } from "ol/proj"
import VectorLayer from "ol/layer/Vector"
import VectorSource from "ol/source/Vector"
import { OSM } from "ol/source"
import OLTileLayer from "ol/layer/Tile"
import { addLocalStorage } from "../Utils/LocalStorage"
import { useDispatch } from "react-redux"
import { setForecast } from "../redux/reducers/forecastSlice"

const Map = ({ children, zoom, center, features }) => {
    const mapRef = useRef()
    const [map, setMap] = useState(null)
    const [featuresLayer, setFeaturesLayer] = useState()
    const dispatch = useDispatch()
    // on component mount
    useEffect(() => {
        const initialFeaturesLayer = new VectorLayer({
            source: new VectorSource({
                features: features
            })
        })
        let options = {
            view: new ol.View({ zoom, center }),
            layers: [
                new OLTileLayer({
                    source: new OSM(),
                    zIndex: 0,
                }),
                initialFeaturesLayer
            ],
            controls: [],
            overlays: []
        }
        let mapObject = new ol.Map(options)
        mapObject.setTarget(mapRef.current)

        setMap(mapObject)
        setFeaturesLayer(initialFeaturesLayer)
        return () => mapObject.setTarget(undefined)
    }, [])
    useEffect(() => {
        if (!map) return
        //change map color
        map.on('postcompose', function (e) {
            const canvas = document.querySelector('canvas')
            canvas.style.filter = "invert(90%)"
            canvas.style.width = '100%'
            canvas.style.height = '100%'
        })
        map.on('click', function (evt) {
            var coords = toLonLat(evt.coordinate)
            var lat = coords[1]
            var lon = coords[0]
            var locTxt = "Latitude: " + lat + " Longitude: " + lon
            console.log(locTxt)
            fetch(`https://f1.weather.gov/MapClick.php?&lat=${lat}&lon=${lon}&FcstType=json`).then(val => val.json())
                .then(json => {
                    addLocalStorage({ text: json.location.areaDescription }, { x: lon, y: lat }, dispatch)
                    return json
                })
                .then(val => dispatch(setForecast(val)))
        })
    }, [map])
    // zoom change handler
    useEffect(() => {
        if (!map) return
        map.getView().setZoom(zoom)
    }, [zoom])
    // center change handler
    useEffect(() => {
        if (!map) return
        map.getView().setCenter(center)
    }, [center])

    // update map if features prop changes - logic formerly put into componentDidUpdate
    useEffect(() => {
        if (features.length && featuresLayer) { // may be null on first render
            // set features to map
            featuresLayer.setSource(
                new VectorSource({
                    features: features // make sure features is an array
                })
            )
            featuresLayer.getSource().changed()
        }
    }, [features])
    return (
        <MapContext.Provider value={{ map }}>
            <div ref={mapRef} className="ol-map" style={{ width: 'auto' }}>
                {children}
            </div>
        </MapContext.Provider>
    )
}
export default Map