import React, { useRef, useState } from 'react'
import { Autocomplete, MenuItem, Stack, TextField, Tooltip, Typography } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { useDispatch } from 'react-redux'
import { setForecast } from '../redux/reducers/forecastSlice'
import { addLocalStorage } from '../Utils/LocalStorage'

export default function WeatherInput(props) {
    console.log("HERE")

    const [inputLocation, setInputLocation] = useState('')
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null)
    const [locations, setLocations] = useState([{ text: 'Use Current Location' }])
    const [isOpen, setIsOpen] = useState(false)

    const dispatch = useDispatch()

    const stackRef = useRef(null)

    const open = Boolean(anchorEl)

    const handleClick = () => {
        setAnchorEl(stackRef.current)
    }

    function handleLocationChanged(reason, location) {
        if (location.length < 1) {
            setLocations([{ text: 'Use Current Location' }])
            return
        }

        setInputLocation(location)
        if (reason === 'input') {
            fetch('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&countryCode=USA%2CPRI%2CVIR%2CGUM%2CASM&category=Land+Features%2CBay%2CChannel%2CCove%2CDam%2CDelta%2CGulf%2CLagoon%2CLake%2COcean%2CReef%2CReservoir%2CSea%2CSound%2CStrait%2CWaterfall%2CWharf%2CAmusement+Park%2CHistorical+Monument%2CLandmark%2CTourist+Attraction%2CZoo%2CCollege%2CBeach%2CCampground%2CGolf+Course%2CHarbor%2CNature+Reserve%2COther+Parks+and+Outdoors%2CPark%2CRacetrack%2CScenic+Overlook%2CSki+Resort%2CSports+Center%2CSports+Field%2CWildlife+Reserve%2CAirport%2CFerry%2CMarina%2CPier%2CPort%2CResort%2CPostal%2CPopulated+Place&maxSuggestions=10&'
                + 'text=' + location)
                .then(response => response.json()).then(data => setLocations(data.suggestions)).then(data => handleClick())
        }

    }

    function handleLocationSelected(reason, location) {
        if (location.text === 'Use Current Location') {
            navigator.geolocation.getCurrentPosition((position) => {
                console.log("Latitude is :", position.coords.latitude)
                console.log("Longitude is :", position.coords.longitude)
                const lat = position.coords.latitude
                const lon = position.coords.longitude
                fetch(`https://f1.weather.gov/MapClick.php?&lat=${lat}&lon=${lon}&FcstType=json`).then(val => val.json())
                    .then(json => {
                        addLocalStorage({ text: json.location.areaDescription }, { x: lon, y: lat }, dispatch)
                        return json
                    })
                    .then(val => dispatch(setForecast(val)))
            }, (error) => console.log(error))
            return
        }
        if (reason === 'createOption') {
            reason = 'selectOption'
            location = locations[0]
            setSelectedLocation(locations[0])
        }
        if (reason === 'selectOption') {
            fetch('https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/find?text=' + location.text + '&magicKey=' + location.magicKey + '&f=json')
                .then(response => response.json())
                .then(data => data.locations[0].feature.geometry)
                .then(json => addLocalStorage(location, json, dispatch))
                .then(geom => fetch(`https://f1.weather.gov/MapClick.php?&lat=${geom.y}&lon=${geom.x}&FcstType=json`))
                .then(response => response.json()).then(forecastJson => dispatch(setForecast(forecastJson)))
        }
    }


    return (
        <Stack direction={'row'} ref={stackRef} display='flex' flexGrow={1} paddingTop='0px'>
            <Autocomplete
                autoHighlight
                disablePortal
                popupIcon={<SearchIcon />}
                options={locations?.map(option => option) ?? []}
                getOptionLabel={option => option.text ?? ''}
                sx={{ minWidth: 150, maxWidth: 300, width: '100%', "& .MuiAutocomplete-popupIndicator": { transform: "none" }, "& .MuiOutlinedInput-root": { height: '50px' } }}
                onInputChange={(event, value, reason) => handleLocationChanged(reason, value)}
                onOpen={() => setIsOpen(true)}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                value={selectedLocation}
                openText='Search'
                onChange={(event, value, reason) => handleLocationSelected(reason, value)}
                renderInput={(params) =>
                    <TextField {...params} label="Set Location ..." value={inputLocation} />}
                renderOption={(props, option) => <Tooltip followCursor title={option.text} {...props}><MenuItem><Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>{option.text}</Typography></MenuItem></Tooltip>}
            />
        </Stack>
    )
}
