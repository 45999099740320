import { createSlice } from '@reduxjs/toolkit'

export const hourlySlice = createSlice({
    name: 'hourly',
    initialState: {
        hourlyJsonData: null,
        hourlyTimeframe: 0,
    },
    reducers: {
        nextHourlyTimeframe: (state, action) => {
            state.hourlyTimeframe = state.hourlyTimeframe === action.payload ? 0 : state.hourlyTimeframe + 1
        },
        prevHourlyTimeframe: (state, action) => {
            state.hourlyTimeframe = state.hourlyTimeframe === 0 ? action.payload : state.hourlyTimeframe - 1
        },
        setHourlyTimeframe: (state, action) => {
            state.hourlyTimeframe = action.payload
        },
        setHourlyJsonData: (state, action) => {
            state.hourlyJsonData = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { nextHourlyTimeframe, prevHourlyTimeframe, setHourlyTimeframe, setHourlyJsonData } = hourlySlice.actions

export default hourlySlice.reducer