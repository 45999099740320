import { alpha, Card, IconButton, Paper, Stack, styled, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getWeatherImage } from '../Utils/Resources'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { nextHourlyTimeframe, prevHourlyTimeframe, setHourlyJsonData } from '../redux/reducers/hourlySlice'

function HourlyForecast() {

    const StyledStack = styled(Stack)({
        '&:hover': {
            overflowX: 'overlay',
        },
    })

    const forecastJson = useSelector(state => state.forecast.forecast)
    const hourlyTimeframe = useSelector(state => state.hourly.hourlyTimeframe)
    const hourlyJsonData = useSelector(state => state.hourly.hourlyJsonData)

    const dispatch = useDispatch()

    useEffect(() => {
        loadHourlyForPeriod()?.then(val => dispatch(setHourlyJsonData(val)))
    }, [forecastJson])


    function loadHourlyForPeriod() {
        if (!forecastJson || hourlyTimeframe == null) {
            return
        }
        return fetch(`https://forecast.weather.gov/MapClick.php?lat=${forecastJson.location.latitude}&lon=${forecastJson.location.longitude}&unit=0&lg=english&FcstType=digitalJSON`).then(val => val.json())
    }

    let currentHourlyJson
    let hourlyDayLength
    if (hourlyJsonData) {
        currentHourlyJson = hourlyJsonData[hourlyJsonData.PeriodNameList[hourlyTimeframe]]
        hourlyDayLength = Object.values(hourlyJsonData.PeriodNameList).filter(val => hourlyJsonData[val] !== undefined).length - 1
    }

    return (
        (!forecastJson || hourlyTimeframe == null) ? <></> :
            <Card variant='outlined' sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 max-content', flexBasis: '400px' }}>
                <Paper elevation={6} sx={{ padding: '5px', display: 'flex', flexDirection: 'row' }}>
                    <IconButton onClick={() => dispatch(prevHourlyTimeframe(hourlyDayLength))}>
                        <ArrowBackIcon></ArrowBackIcon>
                    </IconButton>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1 1 0%' }}>
                        <Typography textAlign='left' fontSize={14}>Hourly Forecast</Typography>
                        {currentHourlyJson ?
                            <Typography textAlign='left' fontSize={18}>{currentHourlyJson.periodName}</Typography> : <></>}
                    </div>
                    <IconButton onClick={() => dispatch(nextHourlyTimeframe(hourlyDayLength))}>
                        <ArrowForwardIcon></ArrowForwardIcon>
                    </IconButton>
                </Paper>


                {currentHourlyJson ?
                    <StyledStack direction='column' rowGap={1} padding='10px' sx={{ overflowX: 'hidden' }}>
                        <Stack direction='row' columnGap={5}>
                            {currentHourlyJson.time.map((val, index) => <Typography fontSize='12pt' textAlign='center' display='flex' flexDirection='column' flex={1} justifyContent='center' minWidth='86px' key={`hourlyTime${index}`}>
                                {val}
                            </Typography>)}
                        </Stack>
                        <Stack direction='row' columnGap={5}>
                            {currentHourlyJson.iconLink.map((val, index) =>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: '1', justifyContent: 'center', minWidth: '86px', position: 'relative' }} key={`hourlyIcon${index}`}>
                                    <img style={{ borderRadius: '5px', objectFit: 'scale-down', minWidth: '86px', minHeight: '86px' }} src={getWeatherImage(val)} />
                                    <Typography fontSize={16} fontWeight='bold' textAlign='end' sx={{ position: 'absolute', bottom: '0px', backgroundColor: alpha('#dddddd', .8), color: '#00487b', width: '100%', borderRadius: '0 0 5% 5%', maxWidth: '134px', paddingRight: '3px' }}>
                                        {currentHourlyJson.pop[index]}%
                                    </Typography>
                                </div>
                            )}
                        </Stack>
                        <Stack direction='row' columnGap={5} paddingBottom={0}>
                            {currentHourlyJson.weather.map((val, index) => <Typography fontSize='11pt' textAlign='center' display='flex' flexDirection='column' flex={1} justifyContent='center' minWidth='86px' key={`hourlyWeather${index}`}>
                                {val}
                            </Typography>)}
                        </Stack>
                        <Stack direction='row' columnGap={5} paddingBottom={1}>
                            {currentHourlyJson.temperature.map((val, index) => <Typography fontSize='12pt' textAlign='center' display='flex' flexDirection='column' flex={1} justifyContent='center' minWidth='86px' key={`hourlyTemperature${index}`}>
                                {val}°F
                            </Typography>)}
                        </Stack>
                    </StyledStack>
                    : <></>}

            </Card>
    )
}

export default HourlyForecast